<template>
  <Drawer
    v-model="isShow"
    width="1200"
    scrollable
    title="商品订单明细"
    draggable
  >
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="80"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="订单编号:" prop="ordercode">
              <Input placeholder="请输入订单编号" v-model="params.ordercode" />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="会员编号:" prop="membercode">
              <Input placeholder="请输入会员编号" v-model="params.membercode" />
            </FormItem>
          </Col>
          <Col span="6">
            <Button
              class="ml2 mt5"
              type="primary"
              size="small"
              icon="ios-search"
              @click="handleSearch"
              >搜索</Button
            >
            <Button class="ml2 mt5" size="small" @click="handleReset"
              >重置</Button
            >
          </Col>
        </Row>
      </div>
    </Form>
    <Table
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="orderTable"
      :columns="tableOption"
      :data="resObj.DataList"
      highlight-row
      size="small"
    >
      <template slot="action" slot-scope="{ row }">
        <Button size="small" type="primary" @click="openDetail(row, false)"
          >订单详情</Button
        >
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="8" class="mt5">
        <Button
          type="primary"
          class="ml2"
          size="small"
          @click="exportOrder"
          icon="md-cloud-download"
          >导出</Button
        >
      </Col>
      <Col span="16" class="flex-end">
        <Page
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
  </Drawer>
</template>

<script>
import api from "@/api";
import moment from "moment";
export default {
  name: "wait-ship-order-detail",
  data() {
    return {
      tableLoading: false,
      tableHeight: 400,
      isShow: false,
      params: {
        customer: 0,
        shipperType: 0,
        productid: "",
        ordercode: "",
        membercode: "",
        begindate: "",
        enddate: "",
        CheckTest: 1,
        Page: 1,
        PageSize: 10,
        SortName: "ID",
        SortOrder: "Desc",
      },
      tableOption: [
        { title: "订单编号", key: "OrderCode", minWidth: 175 },
        { title: "会员编号", key: "MemberCode", minWidth: 90 },
        { title: "购买数量", key: "ProductCount", minWidth: 80 },
        { title: "金额", key: "ProductDetailAmount", minWidth: 80 },
        { title: "状态", key: "OrderStatusCN", minWidth: 80 },
        { title: "发货时间", key: "ShippingDate", minWidth: 150 },
        { title: "操作", key: "action", minWidth: 100, slot: "action" },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
    };
  },
  watch: {
    isShow: function (val) {
      if (val) {
        this.loadData();
      }
    },
  },
  methods: {
    handleSearch() {
      this.params.Page = 1;
      this.loadData();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.loadData();
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadData();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadData();
    },
    openDetail(row) {
      this.$parent.$parent.$refs.DetailDrawer.obj = row;
      this.$parent.$parent.$refs.DetailDrawer.isShow = true;
      this.$parent.$parent.$refs.DetailDrawer.isEdit = false;
    },
    async exportOrder() {
      try {
        let filename = `待发货商品订单 ${moment().format("YYYYMMDD")}.xls`;
        this.params.exportName = filename;
        await api.ExportWaitShipByProduct(this.params);
        this.$Message.success("导出成功");
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
        console.error(error);
      }
    },
    async loadData() {
      this.tableLoading = true;
      try {
        const res = await api.GetWaitShipOrderListByProduct(this.params);
        this.resObj = res.Data;
        this.tableLoading = false;
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
        this.tableLoading = false;
      }
    },
  },
};
</script>

<style lang="scss"></style>
